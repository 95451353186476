import React from "react"
import styled from "styled-components"
import Seo from "../components/Layout/Seo"
import Site from "../components/Layout/Site"
import Element from "../components/UI/Element"
import { Container } from "../components/UI/Grid"
import Hr from "../components/UI/Hr"
import { Section } from "../components/UI/Section"
import { Anchor, Paragraph } from "../components/UI/Typography"

const Terms = () => {
  return (
    <Site>
      <Seo title="Terms of Use" />
      <Section>
        <Container>
          <Element as="h1" fs="h1">
            Terms and Conditions
          </Element>
          <Hr bg="#BDBDBD" mb={6} />
          <Element as="ol" pl="4">
            <Element as="li" fontWeight="bold">
              Introduction
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={4}>
                  Welcome to{" "}
                  <Element as="span" fontWeight="bold">
                    Boeing Material Handling Corp.
                  </Element>{" "}
                  (“Company”, “we”, “our”, “us”)!
                </Paragraph>
                <Paragraph mb={4}>
                  These Terms of Service (“Terms”, “Terms of Service”) govern
                  your use of our website located at{" "}
                  <Element as="span" fontWeight="bold">
                    www.boeingmhc.com.ph
                  </Element>{" "}
                  (together or individually “Service”) operated by{" "}
                  <Element as="span" fontWeight="bold">
                    Boeing Material Handling Corp.
                  </Element>
                </Paragraph>
                <Paragraph>
                  Our Privacy Policy also governs your use of our Service and
                  explains how we collect, safeguard and disclose information
                  that results from your use of our web pages.
                </Paragraph>
                <Paragraph>
                  Your agreement with us includes these Terms and our Privacy
                  Policy (“Agreements”). You acknowledge that you have read and
                  understood Agreements, and agree to be bound of them.
                </Paragraph>
                <Paragraph mb={10}>
                  If you do not agree with (or cannot comply with) Agreements,
                  then you may not use the Service, but please let us know by
                  emailing at{" "}
                  <Anchor
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:contact@boeingmhc.com.ph"
                    fontWeight="bold"
                  >
                    contact@boeingmhc.com.ph
                  </Anchor>{" "}
                  so we can try to find a solution. These Terms apply to all
                  visitors, users and others who wish to access or use Service.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Communications
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  By using our Service, you agree to subscribe to newsletters,
                  marketing or promotional materials and other information we
                  may send. However, you may opt out of receiving any, or all,
                  of these communications from us by following the unsubscribe
                  link or by emailing at contact@boeingmhc.com.ph.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Contest, Sweepstakes and Promotions
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  Any contest, sweepstakes or other promotions (collectively,
                  “Promotions”) made available through Service may be governed
                  by rules that are separate from these Terms of Service. If you
                  participate in any Promotions, please review the applicable
                  rules as well as our Privacy Policy. If the rules for a
                  Promotion conflict with these Terms of Service, Promotion
                  rules will apply.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Content
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  Content found on or through this Service are the property of
                  Boeing Material Handling Corp. or used with permission. You
                  may not distribute, modify, transmit, reuse, download, repost,
                  copy, or use said Content, whether in whole or in part, for
                  commercial purposes or for personal gain, without express
                  advance written permission from us.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Prohibited Uses
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={2}>
                  You may use Service only for lawful purposes and in accordance
                  with Terms. You agree not to use Service:
                </Paragraph>
                <CustomList>
                  <Element as="li" data-type="0.1.">
                    In any way that violates any applicable national or
                    international law or regulation.
                  </Element>
                  <Element as="li" data-type="0.2.">
                    For the purpose of exploiting, harming, or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content or otherwise.
                  </Element>
                  <Element as="li" data-type="0.3.">
                    To transmit, or procure the sending of, any advertising or
                    promotional material, including any “junk mail”, “chain
                    letter,” “spam,” or any other similar solicitation.
                  </Element>
                  <Element as="li" data-type="0.4.">
                    To impersonate or attempt to impersonate Company, a Company
                    employee, another user, or any other person or entity.
                  </Element>
                  <Element as="li" data-type="0.5.">
                    In any way that infringes upon the rights of others, or in
                    any way is illegal, threatening, fraudulent, or harmful, or
                    in connection with any unlawful, illegal, fraudulent, or
                    harmful purpose or activity.
                  </Element>
                  <Element as="li" data-type="0.6.">
                    To engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of Service, or which, as
                    determined by us, may harm or offend Company or users of
                    Service or expose them to liability.
                  </Element>
                </CustomList>
              </Element>
              <Element fontWeight="normal" mt={4} mb={10}>
                <Paragraph mb={2}>Additionally, you agree not to:</Paragraph>
                <CustomList>
                  <Element as="li" data-type="0.1.">
                    Use Service in any manner that could disable, overburden,
                    damage, or impair Service or interfere with any other
                    party’s use of Service, including their ability to engage in
                    real time activities through Service.
                  </Element>
                  <Element as="li" data-type="0.2.">
                    Use any robot, spider, or other automatic device, process,
                    or means to access Service for any purpose, including
                    monitoring or copying any of the material on Service.
                  </Element>
                  <Element as="li" data-type="0.3.">
                    Use any manual process to monitor or copy any of the
                    material on Service or for any other unauthorized purpose
                    without our prior written consent.
                  </Element>
                  <Element as="li" data-type="0.4.">
                    Use any device, software, or routine that interferes with
                    the proper working of Service.
                  </Element>
                  <Element as="li" data-type="0.5.">
                    Introduce any viruses, trojan horses, worms, logic bombs, or
                    other material which is malicious or technologically
                    harmful.
                  </Element>
                  <Element as="li" data-type="0.6.">
                    Attempt to gain unauthorized access to, interfere with,
                    damage, or disrupt any parts of Service, the server on which
                    Service is stored, or any server, computer, or database
                    connected to Service.
                  </Element>
                  <Element as="li" data-type="0.7.">
                    Attack Service via a denial-of-service attack or a
                    distributed denial-of-service attack.
                  </Element>
                  <Element as="li" data-type="0.8.">
                    Take any action that may damage or falsify Company rating.
                  </Element>
                  <Element as="li" data-type="0.9.">
                    Otherwise attempt to interfere with the proper working of
                    Service.
                  </Element>
                </CustomList>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Analytics
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Intellectual Property
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  Service and its original content (excluding Content provided
                  by users), features and functionality are and will remain the
                  exclusive property of Boeing Material Handling Corp. and its
                  licensors. Service is protected by copyright, trademark, and
                  other laws of and foreign countries. Our trademarks may not be
                  used in connection with any product or service without the
                  prior written consent of Boeing Material Handling Corp.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Copyright Policy
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={4}>
                  We respect the intellectual property rights of others. It is
                  our policy to respond to any claim that Content posted on
                  Service infringes on the copyright or other intellectual
                  property rights (“Infringement”) of any person or entity.
                </Paragraph>
                <Paragraph mb={4}>
                  If you are a copyright owner, or authorized on behalf of one,
                  and you believe that the copyrighted work has been copied in a
                  way that constitutes copyright infringement, please submit
                  your claim via email to contact@boeingmhc.com.ph, with the
                  subject line: “Copyright Infringement” and include in your
                  claim a detailed description of the alleged Infringement as
                  detailed below, under “DMCA Notice and Procedure for Copyright
                  Infringement Claims”
                </Paragraph>
                <Paragraph mb={10}>
                  You may be held accountable for damages (including costs and
                  attorneys’ fees) for misrepresentation or bad-faith claims on
                  the infringement of any Content found on and/or through
                  Service on your copyright.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              DMCA Notice and Procedure for Copyright Infringement Claims
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={2}>
                  You may submit a notification pursuant to the Digital
                  Millennium Copyright Act (DMCA) by providing our Copyright
                  Agent with the following information in writing (see 17 U.S.C
                  512(c)(3) for further detail):
                </Paragraph>
                <CustomList>
                  <Element as="li" data-type="0.1.">
                    an electronic or physical signature of the person authorized
                    to act on behalf of the owner of the copyright’s interest;
                  </Element>
                  <Element as="li" data-type="0.2.">
                    a description of the copyrighted work that you claim has
                    been infringed, including the URL (i.e., web page address)
                    of the location where the copyrighted work exists or a copy
                    of the copyrighted work;
                  </Element>
                  <Element as="li" data-type="0.3.">
                    identification of the URL or other specific location on
                    Service where the material that you claim is infringing is
                    located;
                  </Element>
                  <Element as="li" data-type="0.4.">
                    your address, telephone number, and email address;
                  </Element>
                  <Element as="li" data-type="0.5.">
                    a statement by you that you have a good faith belief that
                    the disputed use is not authorized by the copyright owner,
                    its agent, or the law;
                  </Element>
                  <Element as="li" data-type="0.6.">
                    a statement by you, made under penalty of perjury, that the
                    above information in your notice is accurate and that you
                    are the copyright owner or authorized to act on the
                    copyright owner’s behalf.
                  </Element>
                </CustomList>
                <Paragraph mb={10} mt={2}>
                  You can contact our Copyright Agent via email at
                  contact@boeingmhc.com.ph.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Error and Reporting Feedback
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  You may provide us either directly at contact@boeingmhc.com.ph
                  or via third party sites and tools with information and
                  feedback concerning errors, suggestions for improvements,
                  ideas, problems, complaints, and other matters related to our
                  Service (“Feedback”). You acknowledge and agree that: (i) you
                  shall not retain, acquire or assert any intellectual property
                  right or other right, title or interest in or to the Feedback;
                  (ii) Company may have development ideas similar to the
                  Feedback; (iii) Feedback does not contain confidential
                  information or proprietary information from you or any third
                  party; and (iv) Company is not under any obligation of
                  confidentiality with respect to the Feedback. In the event the
                  transfer of the ownership to the Feedback is not possible due
                  to applicable mandatory laws, you grant Company and its
                  affiliates an exclusive, transferable, irrevocable,
                  free-of-charge, sub-licensable, unlimited and perpetual right
                  to use (including copy, modify, create derivative works,
                  publish, distribute and commercialize) Feedback in any manner
                  and for any purpose.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Links To Other Websites
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={4}>
                  Our Service may contain links to third party web sites or
                  services that are not owned or controlled by Boeing Material
                  Handling Corp.
                </Paragraph>
                <Paragraph mb={4}>
                  Boeing Material Handling Corp. has no control over, and
                  assumes no responsibility for the content, privacy policies,
                  or practices of any third-party web sites or services. We do
                  not warrant the offerings of any of these entities/individuals
                  or their websites.
                </Paragraph>
                <Paragraph mb={4}>
                  For example, the outlined Terms of Use have been created using
                  PolicyMaker.io, a free web application for generating
                  high-quality legal documents. PolicyMaker’s Terms and
                  Conditions generator is an easy-to-use free tool for creating
                  an excellent standard Terms of Service template for a website,
                  blog, e-commerce store or app.
                </Paragraph>
                <Paragraph mb={10}>
                  YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE
                  RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE
                  OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION
                  WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES
                  AVAILABLE ON OR THROUGH ANY SUCH THIRD-PARTY WEB SITES OR
                  SERVICES. WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE
                  AND PRIVACY POLICIES OF ANY THIRD-PARTY WEB SITES OR SERVICES
                  THAT YOU VISIT.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Disclaimer Of Warranty
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={4}>
                  THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
                  AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR
                  WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                  OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR
                  MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE
                  OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS
                  OBTAINED FROM US IS AT YOUR SOLE RISK.
                </Paragraph>
                <Paragraph mb={4}>
                  NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES
                  ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                  COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                  AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING,
                  NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS
                  OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES
                  OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
                  RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                  CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT
                  AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
                  THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                  THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                </Paragraph>
                <Paragraph mb={4}>
                  COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                  EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
                  LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                  NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                </Paragraph>
                <Paragraph mb={10}>
                  THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                  EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Limitation Of Liability
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                  OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY
                  INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
                  DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’ FEES AND ALL
                  RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR
                  AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
                  ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT,
                  NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
                  CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION
                  ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM
                  THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE,
                  OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF
                  COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY
                  FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
                  PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO
                  CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES.
                  SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR
                  LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Termination
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={4}>
                  We may terminate or suspend you account and bar access to
                  Service immediately, without prior notice or liability, under
                  our sole discretion, for any reason whatsoever and without
                  limitation, including but not limited to a breach of Terms.
                </Paragraph>
                <Paragraph mb={4}>
                  If you with to terminate you account, you may simply
                  discontinue using Service.
                </Paragraph>
                <Paragraph mb={10}>
                  All provision of Terms which by their nature should survive
                  termination shall survive termination, including, without
                  limitation, ownership provisions, warranty disclaimers,
                  indemnity and limitations of liability.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Governing Law
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={4}>
                  These Terms shall be governed and construed in accordance with
                  the laws of The Philippines, which governing law applies to
                  agreement without regard to its conflict of law provisions.
                </Paragraph>
                <Paragraph mb={10}>
                  Our failure to enforce any right or provision of these Terms
                  will not be considered a waiver of those rights. If any
                  provision of these Terms is held to be invalid or
                  unenforceable by a court, the remaining provisions of these
                  Terms will remain in effect. These Terms constitute the entire
                  agreement between us regarding our Service and supersede and
                  replace any prior agreements we might have had between us
                  regarding Service.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Changes To Service
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  We reserve the right to withdraw or amend our Service, and any
                  service or material we provide via Service, in our sole
                  discretion without notice. We will not be liable if for any
                  reason all or any part of Service is unavailable at any time
                  or for any period. From time to time, we may restrict access
                  to some parts of Service, or the entire Service, to users,
                  including registered users.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Amendments To Terms
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={4}>
                  We may amend Terms at any time by posting the amended terms on
                  this site. It is your responsibility to review these Terms
                  periodically.
                </Paragraph>
                <Paragraph mb={4}>
                  Your continued use of the Platform following the posting of
                  revised Terms means that you accept and agree to the changes.
                  You are expected to check this page frequently so you are
                  aware of any changes, as they are binding on you.
                </Paragraph>
                <Paragraph mb={10}>
                  By continuing to access or use our Service after any revisions
                  become effective, you agree to be bound by the revised terms.
                  If you do not agree to the new terms, you are no longer
                  authorized to use Service.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Waiver and Severability
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={4}>
                  No waiver by Company of any term or condition set forth in
                  Terms shall be deemed a further or continuing waiver of such
                  term or condition or a waiver of any other term or condition,
                  and any failure of Company to assert a right or provision
                  under Terms shall not constitute a waiver of such right or
                  provision.
                </Paragraph>
                <Paragraph mb={10}>
                  If any provision of Terms is held by a court or other tribunal
                  of competent jurisdiction to be invalid, illegal or
                  unenforceable for any reason, such provision shall be
                  eliminated or limited to the minimum extent such that the
                  remaining provisions of Terms will continue in full force and
                  effect.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Acknowledgment
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  By using service or other services provided by us you
                  acknowledge that you have read these terms of service and
                  agree to be bound by them.
                </Paragraph>
              </Element>
            </Element>

            <Element as="li" fontWeight="bold">
              Contact Us
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={10}>
                  Please send your feedback, comments, requests for technical
                  support by email:{" "}
                  <Anchor
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:contact@boeingmhc.com.ph"
                    fontWeight="bold"
                  >
                    contact@boeingmhc.com.ph
                  </Anchor>
                  .
                </Paragraph>
              </Element>
            </Element>
          </Element>
        </Container>
      </Section>
    </Site>
  )
}

const CustomList = styled.ul`
  list-style-type: none;
  padding-left: 24px;
  li {
    position: relative;
    padding-left: 32px;

    & + li {
      margin-top: 12px;
    }

    &:before {
      content: attr(data-type);
      position: absolute;
      left: 0;
    }
  }
`

export default Terms
